<template>
  <div>
    <div class="row no-gutters">
       <content-filter
        class="col-8 mb-2 mr-2"
        :id="listId"
        @filter="filtering"
        type="miniFilter"
        store-name="Filter"
        :org-filtering="true"
        :limit-to-organization-ids="[organizationId]"
        :org-filter-local="true"
      />
      <div class="col">
        <div class="mr-2 settings-button d-inline-block float-right" @click="$emit('create')">{{ $t('New') }} <icon size="0.9" class="ml-1" type="plus" /></div>
      </div>
    </div>

    <pagination
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getFilterListOptions($route.params.id, 'pagination_items')"
        id="exportprofileList"
        @setActive="loadPaginatedData"
    />
    <div class="mb-1 mt-1 export-profile-teaser"
         :key="id"
         v-for="(item, id) in $store.getters.getFiltersByListName(listId)">
      <div class="row">
          <div class="icon-button bg-dark border-radius p-1 pl-2 pr-2 profile-edit-button" @click="$emit('edit', item.id)"><icon type="cog" /></div>
          <div class="col"><p class="larger"><b>{{ item.name }}</b></p>
          <p class="lighter smaller">{{ $t('Created') }}: {{ dateFromISO(item.createdAt) }}</p>
            <p class="lighter smaller">{{ $t('Last change') }}: {{ dateFromISO(item.updatedAt) }}</p>

            <div v-if="item.description" class="mb-1">{{ item.description }}<br ></div>
          <!--<tag class="mt-2 mb-2 mr-1" icon-left="users" :is-removable="false" :key="team.id" v-for="team in item.teams" :tag="team" :organization-id="team.organizationId" />-->
          <!--<div class="darker p-2">
            {{ item.content && JSON.parse(item.content) ? JSON.parse(item.content).filter : ''}}
          </div>-->
            <!--<state-display class="float-right" :state="item.state" />-->
          <div class="settings-button d-inline-block switched-colors mt-1 move-to-right-animation highlighted" @click="$emit('createInstance', item)">{{ $t('createNewSFXFromExportProfile') }} <icon type="angle-right" /></div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import ContentFilter from "@/components/widgets/filter/ContentFilter";
import DateMixin from "@/components/mixins/DateMixin.js";
import Icon from "@/components/Icon";
import Pagination from "@/components/Pagination";
//import Tag from "@/components/Tag";
//import StateDisplay from "@/components/StateDisplay";

export default {
  name: "ExportProfileList",
  components: {
    ContentFilter,
    Icon,
    Pagination,
    //Tag,
    //StateDisplay
  },
  mixins: [DateMixin],
  props: {
    organizationId: {type: String, required: true},
    listId: {type: String, required: true}
  },
  data() {
    return {
      paginationLimit: 8,
      offset: 0,
      list: {},
      filter: {},
      queryRelevantComponents: 2, //pagination and filter
      queryRelevantInits: 0,
      teams: []
    }
  },
  methods: {
    loadFilters(initial = false) {
      let args =  {
        filter: 'type eq filter',
        listName: this.listId,
      }
      if(this.filter) {
        if (this.filter.add) { args.add = this.filter.add}
        if (this.filter.remove) { args.remove = this.filter.remove }
      }
      const paginationAddOn = {
        limit: {
          default: this.paginationLimit
        },
        offset: {
          default: this.offset,
        },
        paging: {
          default: true
        },
        include: {
          default: 'teams'
        }
      }
      args.add = this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
      if(!initial || this.queryRelevantInits >= this.queryRelevantComponents) {
        this.$store.dispatch('loadFilters', args).then(data => {
          this.list = data;
        });
      } else {
        this.$store.dispatch('registerFilterQueryParams', {listName: this.listId, params: args});
      }
    },
    filtering(val, initial) {
      this.queryRelevantInits++;
      this.filter = val;
      this.loadFilters(initial);
    },
    loadPaginatedData(offset, initial) {
      this.queryRelevantInits++;
      this.offset = offset;
      this.loadFilters(initial);
    }
  }
}
</script>

<style lang="scss">
  .export-profile-teaser {
    position:relative;
    background: var(--vform-editor-ui-secondary-color);
    padding: 5px 10px 8px 10px;
    p {
      z-index: 0;
      position:relative;
    }
  }
  .profile-edit-button {
    z-index:1;
    position:absolute;
    top:8px;
    right: 8px;
    display:inline-block;
  }
</style>